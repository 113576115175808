// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-o-mnie-js": () => import("./../../../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-oferta-edukacyjna-js": () => import("./../../../src/pages/oferta-edukacyjna.js" /* webpackChunkName: "component---src-pages-oferta-edukacyjna-js" */),
  "component---src-pages-oferta-terapeutyczna-js": () => import("./../../../src/pages/oferta-terapeutyczna.js" /* webpackChunkName: "component---src-pages-oferta-terapeutyczna-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-skontaktuj-sie-js": () => import("./../../../src/pages/skontaktuj-sie.js" /* webpackChunkName: "component---src-pages-skontaktuj-sie-js" */),
  "component---src-pages-superwizja-js": () => import("./../../../src/pages/superwizja.js" /* webpackChunkName: "component---src-pages-superwizja-js" */)
}

